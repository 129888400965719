import FormControl from '@mui/material/FormControl';
import { TextField } from '@mui/material';
import { useState } from 'react';
import {Button} from '@mui/material';
import { useNavigate } from 'react-router-dom';

export default function ChangePassword({accountId}){
        const [password, setPassword] = useState('');
        const [confirmPassword, setConfirmPassword] = useState('');
        const navigate = useNavigate();

        const handlePassword=(event)=>{
            setPassword(event.target.value)
        }

        const handleConfirm=(event)=>{
            setConfirmPassword(event.target.value)
        }

        const handleLogOut=()=>{
          localStorage.clear();
          navigate('/login',{replace: true})
        }

        async function updatePassword(){
            if(password == confirmPassword){
            const body = {
                "accountId": `${accountId}`,
                "Password": `${password}`
              }
            const response = await fetch(process.env.REACT_APP_BASE_URL + `/api/appaccount/update-account-password`, {
              method: 'PATCH',
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(body)
            });
      
            if(response.ok){
              alert('Password Updated Successfully');
            }
      
            if(response.status === 400){
              alert('Error Updating Integration Access')
            }
      
            if(response.status === 403){
              alert('Permission denied')
              handleLogOut()
            }
            }else{
                alert('Password Confirmation failed');
            }
        }

    return (
    <div>
        <FormControl sx={{ mt: 2, minWidth: 120 }}>
            <TextField id="outlined-basic" sx={{width:350}} type="password" value={password} onChange={handlePassword} fullWidth label="Password" size='medium' margin='normal' variant="outlined" />
            <TextField id="outlined-basic" sx={{width:350}} type="password" fullWidth value={confirmPassword} onChange={handleConfirm} label="Confirm Password" margin='normal' variant="outlined" />
        </FormControl>
        
       <br></br>
      <br></br>
      <Button variant='contained' onClick={updatePassword} style={{float:'right'}}>Save</Button>
    </div>);
}
const styles = {
  root: {
    minHeight: '80vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '25rem',
    maxWidth: '92%',
    margin: '2rem auto'
  },
  root_2: {
    minHeight: '80vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
    maxWidth: '92%',
    margin: '2rem auto'
  },
  box: {
    padding: '1rem',
    width: '100%',
    textAlign: 'center'
  },
  form: {
    width: '100%',
    padding: '1rem'
  },
  form_field: {
    width: '100%'
  },
  button: {
    width: '100%',
    margin: '2rem auto 1rem',
    borderRadius: '0'
  },
  link: {
    marginTop: '1rem'
  }
};

export default styles;
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import ChangePassword from './change-password';
import CreateAccount from './create-account';
import Integrations from './integrations';
import ChangeStatus from './change-status';

export default function ModalDialog({isOpen, title, mode, setClose, accountId, getAccounts, status}) {

  const handleClose = () => {
    setClose(false)
    getAccounts(1,5);
  };

  
  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open max-width dialog
      </Button> */}
      <Dialog
        fullWidth={true}
        maxWidth={'md'}
        open={isOpen}
        onClose={handleClose}
      >
        <DialogTitle><b>{title}</b></DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* Select Account Subscribed Integrations */}
          </DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              m: 'auto',
              width: 'fit-content',
            }}
          >
            {mode == 'integrations' ? 
            <Integrations accountId={accountId}/> : 
              mode == 'password' ? <ChangePassword accountId={accountId}/> :
              mode == 'account' ? <CreateAccount/> :
              <ChangeStatus accountId={accountId} status={status}/>
            }
            {/* <FormControlLabel
              sx={{ mt: 1 }}
              control={
                <Switch checked={fullWidth} onChange={handleFullWidthChange} />
              }
              label="Full width"
            /> */}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
import FormControl from '@mui/material/FormControl';
import { FormGroup, TextField } from '@mui/material';
import { useState } from 'react';
import {Button} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import OutlinedInput from '@mui/material/OutlinedInput';
import { useTheme } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Quickbook',
  'Jira',
  'Introhive'
];



export default function CreateAccount(){
    const theme = useTheme();
    const [accessNames, setAccessNames] = React.useState([]);
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    
    function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

    const handleRole=(event)=>{
        setRole(event.target.value);
    }

        const handlePassword=(event)=>{
            setPassword(event.target.value)
        }

        const handleEmail =(event)=>{
            setEmail(event.target.value)
        }

        const handleChange = (event) => {
            const {
              target: { value },
            } = event;
            setAccessNames(
              // On autofill we get a stringified value.
              typeof value === 'string' ? value.split(',') : value,
            );
          };

        async function createAccount(){
           
            const body = {
                "email": `${email}`,
                "password": `${password}`,
                "confirmPassword": `${password}`,
                "role":`${role}`,
                "integrationAccess": accessNames
              }

            const response = await fetch(process.env.REACT_APP_BASE_URL + `/api/appaccount/signup`, {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(body)
            });
      
            if(response.ok){
              alert('New Account Created Successfully');
              
            }
        
            if (!response.ok) {
              // navigate('/login',{replace: true})
              // handleLogout();
            }
      
            if(response.status === 400){
              console.log(response)
              alert('Error Updating Integration Access')
            }
      
            if(response.status === 403){
              // navigate('/login',{replace: true})
              // handleLogout();
            }
        }

    return (
    <div>
        <FormGroup sx={{ mt: 0, minWidth: 120 }}>
            <TextField sx={{width:350}} id="email-outlined-basic" type="email" value={email} onChange={handleEmail} fullWidth label="Email" size='medium' margin='normal' variant="outlined" />
            <TextField sx={{width:350}} id="password-outlined-basic" type="password" value={password} onChange={handlePassword} fullWidth label="Password" size='medium' margin='none' variant="outlined" />
            <InputLabel id="simple-select-role" style={{marginTop:10}}>Role</InputLabel>
            <Select
                labelId="simple-select-role"
                id="demo-simple-select"
                value={role}
                onChange={handleRole}
            >
                <MenuItem value={'client'}>Client</MenuItem>
                <MenuItem value={'admin'}>Admin</MenuItem>
            </Select>

            <InputLabel id="multiple-chip-access" style={{marginTop:10}}>Integration Access</InputLabel>
            <Select
                labelId="multiple-chip-access"
                id="demo-multiple-chip" sx={{width:350}}
                multiple
                value={accessNames}
                onChange={handleChange}
                input={<OutlinedInput id="select-multiple-chip" />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selected.map((value) => (
                        <Chip key={value} label={value} />
                    ))}
                    </Box>
                )}
                MenuProps={MenuProps}
                >
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, accessNames, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
        </FormGroup>
        
       <br></br>
      <br></br>
      <Button variant='contained' onClick={createAccount} style={{float:'right'}}>Save</Button>
    </div>);
}
import {Button} from '@mui/material';

export default function ChangeStatus({accountId, status}){

        async function updateStatus(){
            const body = {
                "accountId": `${accountId}`,
                "accountStatus": `${status ? 'Active' : 'Inactive'}`
              }
            const response = await fetch(process.env.REACT_APP_BASE_URL + `/api/appaccount/update-account-status`, {
              method: 'PATCH',
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(body)
            });
      
            if(response.ok){
              alert('Integration Access Updated Successfully');
            }
        
            if (!response.ok) {
              // navigate('/login',{replace: true})
              // handleLogout();
            }
      
            if(response.status === 400){
              console.log(response)
              alert('Error Updating Integration Access')
            }
      
            if(response.status === 403){
              // navigate('/login',{replace: true})
              // handleLogout();
            }
        }

    return (
    <div>
        <div>
            Are you sure you want to {status ? 'Activate' : 'Deactivate'} this account?
        </div>
       <br></br>
      <br></br>
      <Button variant='contained' onClick={updateStatus} style={{float:'right'}}>Yes</Button>
    </div>);
}
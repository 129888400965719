import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';

import './App.css';
import NavBar from './components/NavBar/NavBar';
import Home from './routes/Home/Home';
import Login from './routes/User/Login';
import Signup from './routes/User/Signup';
// import ForgotPassword from './routes/User/ForgotPassword';
// import ResetPassword from './routes/User/ResetPassword';
import HubspotOAuth from './routes/Home/HubspotOAuth';
import QuickbookOAuth from './routes/Home/QuickbookOAuth';
import Dashboard from './routes/Dashboard/dashboard';

function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <main>
        <Routes>
          <Route path="/hubspot/callback/*" Component={HubspotOAuth}/>
          <Route path="/quickbook/callback/*" Component={QuickbookOAuth} />

          <Route path="/login" Component={Login} />
          <Route path="/signup" Component={Signup} />
          {/* <Route path="/forgot-password" Component={ForgotPassword} />
          <Route path="/reset-password" Component={ResetPassword} /> */}

          <Route path="/info" Component={Info} />
          <Route path="/dashboard" Component={Dashboard} />
          <Route path="*" Component={Home}/>
        </Routes>
      </main>
    </BrowserRouter>
  );
}

function Info() { return (<div>This is the info page</div>); }

export default App;

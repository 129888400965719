import React, { memo } from 'react';

import styles from './SyncInformation.styles';
import { Box, Typography } from '@mui/material';

function SyncInformation({ syncInformation }) {
  const {
    createdAt,
    invoicesCreated,
    invoicesUpdated,
    issuesCreated,
    issuesUpdated,
    status,
    message,
    invoicesDeleted,
    data
  } = syncInformation.data;

  return (
    <>
      <Box sx={styles.textContainer}>
        <Typography sx={styles.text} variant="body1">
          Sync Time:
        </Typography>
        <Typography variant="body2">
          { createdAt === 'Invalid date' ? 'N/A' : createdAt || 'N/A' }
        </Typography>
      </Box>
      
      <Box sx={styles.textContainer}>
        <Typography sx={styles.text} variant="body1">
          Status:
        </Typography>
        <Typography variant="body2">
          { status }
        </Typography>
      </Box>

      {
        (status === 'Success' || status === 'In Progress' || status === 'No records found') && (
          <>
            { invoicesCreated != null && (
              <Box sx={styles.textContainer}>
                <Typography sx={styles.text} variant="body1">
                  Invoices Created:
                </Typography>
                <Typography variant="body2">
                  { invoicesCreated != null ? invoicesCreated : 'N/A' }
                </Typography>
              </Box>
            )}
            { invoicesUpdated != null && (
              <Box sx={styles.textContainer}>
                <Typography sx={styles.text} variant="body1">
                  Invoices Updated:
                </Typography>
                <Typography variant="body2">
                  { invoicesUpdated != null ? invoicesUpdated : 'N/A' }
                </Typography>
              </Box>
            )}
            { invoicesDeleted != null && (
              <Box sx={styles.textContainer}>
                <Typography sx={styles.text} variant="body1">
                  Invoices Deleted:
                </Typography>
                <Typography variant="body2">
                  { invoicesDeleted != null ? invoicesDeleted : 'N/A' }
                </Typography>
              </Box>
            )}
            { issuesCreated != null && ( 
              <Box sx={styles.textContainer}>
                <Typography sx={styles.text} variant="body1">
                  Issues Created:
                </Typography>
                <Typography variant="body2">
                  { issuesCreated != null ? issuesCreated : 'N/A' }
                </Typography>
              </Box>
            )}
            { issuesUpdated != null && (
              <Box sx={styles.textContainer}>
                <Typography sx={styles.text} variant="body1">
                  Issues Updated:
                </Typography>
                <Typography variant="body2">
                  { issuesUpdated != null ? issuesUpdated : 'N/A' }
                </Typography>
              </Box>
            )}
            { data != null && (
              <>
              <label style={{fontWeight: 'bold'}}>Introhive Sync Details</label>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.text} variant="body1">
                    Message:
                  </Typography>
                  <Typography sx={styles.text} variant="body2">
                    {message}
                  </Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.text} variant="body1">
                    Total Users:
                  </Typography>
                  <Typography sx={styles.text} variant="body2">
                    {data?.introhiveUsers}
                  </Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.text} variant="body1">
                    Total Companies:
                  </Typography>
                  <Typography sx={styles.text} variant="body2">
                    {data?.companies}
                  </Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.text} variant="body1">
                    Total Contacts:
                  </Typography>
                  <Typography sx={styles.text} variant="body2">
                    {data?.contacts}
                  </Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.text} variant="body1">
                    Total Relationships:
                  </Typography>
                  <Typography sx={styles.text} variant="body2">
                    {data?.relationships}
                  </Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.text} variant="body1">
                    Total Sector Codes:
                  </Typography>
                  <Typography sx={styles.text} variant="body2">
                    {data?.sectorCodes}
                  </Typography>
                </Box>
                <Box sx={styles.textContainer}>
                  <Typography sx={styles.text} variant="body1">
                    Total Job Changes:
                  </Typography>
                  <Typography sx={styles.text} variant="body2">
                    {data?.jobChanges}
                  </Typography>
                </Box>
              </>
            )}
          </>
        )
      }
      {
        status === "Error" && (
          <>
            <Box sx={styles.textContainer}>
              <Typography sx={styles.text} variant="body1">
                Reason:
              </Typography>
              <Typography variant="body2">
                { message != null ? message : 'N/A' }
              </Typography>
            </Box>
          </>
        )
      }
    </>
  )
}

SyncInformation.defaultProps = {
  syncInformation: {}
};

export default memo(SyncInformation);
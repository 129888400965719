import { login, signup } from "../slices/userSlice";
import { 
  storeIntegrations, 
  connectedToHubspot, 
  connectedToQuickBooks
} from "../slices/integrationsSlice";
import { getAccessToken, storeInLocalStorage } from "./tokenActions";

export async function verifySession (accessToken, userId) {
  const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/appaccount/verify-session', {
    method: 'POST',
    headers: { 
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ userId })
  });

  const result = await response.json();
  return { ...result }
}

export async function getIntegrations(accessToken) {
  const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/appaccount/get-integrations', {
    method: 'GET',
    headers: { 
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json' 
    }
  });

  const result = await response.json();

  return { ...result }
}

export function loginAction ({ email, password }) {
  return async function (dispatch) {
    var response, result;
    try {
      response = await fetch(process.env.REACT_APP_BASE_URL + '/api/appaccount/signin', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
      });

      result = await response.json();
    } catch (error) {
      result = {
        status: 'failed',
        message: error
      }
    }

    if (result.status === 'success') {
      storeInLocalStorage({
        accessToken: result.accessToken,
        expiresIn: result.expiresIn,
        refreshToken: result.refreshToken,
        userId: result.userId
      });
      dispatch(storeIntegrations({
        integrations: result.integrations
      }));
      dispatch(login({ 
        accessToken: result.accessToken, 
        refreshToken: result.refreshToken,
        userId: result.userId,
        roles: result.roles
      }));
    } else {
      alert(`${result.message}`);
    }
  }
}

export function signupAction (user) {
  const { email, password } = user;

  return async function (dispatch) {
    const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/appaccount/signup', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password })
    });

    const result = await response.json();
    if (result.status === 'success') {
      // successfully signed up, store session token
      storeInLocalStorage({
        accessToken: result.accessToken,
        expiresIn: result.expiresIn,
        refreshToken: result.refreshToken,
        userId: result.userId
      });
      dispatch(signup({ 
        accessToken: result.accessToken,
        refreshToken: result.refreshToken,
        userId: result.userId
      }));
    } else {
      alert(`${result.message}`);
    }
  }
}

export function hubspotCallbackAction (code) {
  return async function (dispatch) {
    const accessToken = await getAccessToken(dispatch);
    const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/hubspot/callback', {
      method: 'POST',
      headers: { 
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ code })
    })
    const result = await response.json();
    
    if (response.status === 200) {
      const { hubspotAccId, hubspotInfo } = result;
      dispatch(connectedToHubspot({ hubspotAccId, hubspotInfo }));
    }
  }
}

export function quickbookCallbackAction (code, realmId) {
  return async function (dispatch) {
    const accessToken = await getAccessToken(dispatch);
    const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/quickbook/callback', {
      method: 'POST',
      headers: { 
        'Authorization': `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ code, realmId })
    })
    const result = await response.json();
    
    if (response.status === 200) {
      const { quickbookAccId, quickbooksInfo } = result;
      dispatch(connectedToQuickBooks({ quickbookAccId, quickbooksInfo }));
    }
  }
}

export async function verifyJiraCredentials (accessToken, username, authToken, jConnected) {
  let response;
  const options = {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ username, authToken })
  }

  if (!jConnected) {
    response = await fetch(process.env.REACT_APP_BASE_URL + '/api/jira/create', options);
  } else {
    response = await fetch(process.env.REACT_APP_BASE_URL + '/api/jira/update', options);
  }
  
  const result = await response.json();
  if (response.status === 200) {
    return {
      success: true,
      message: result.message,
      jiraUsername: result?.jiraInfo?.username
    };
    
  } 
  return { success: false, message: result.message };
  
}

export async function verifyIntrohiveCredentials (accessToken, baseUrl, apiKey, iConnected) {
  let response;
  const options = {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ baseUrl, apiKey })
  }

  if (!iConnected) {
    response = await fetch(process.env.REACT_APP_BASE_URL + '/api/introhive/create', options);
  } else {
    response = await fetch(process.env.REACT_APP_BASE_URL + '/api/introhive/update', options);
  }

  const result = await response.json();
  if (response.ok) {
    return {
      success: true,
      message: result.message,
      introhiveInfo: result?.introhiveInfo
    }
  }

  return {
    success: false,
    message: result.message
  }
}
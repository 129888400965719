import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { Button } from '@mui/material';
import Accounts from './Accounts';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Quickbook',
  'Jira',
  'Introhive'
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function Integrations({accountId}) {
  const theme = useTheme();
  const [accessNames, setAccessNames] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setAccessNames(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  const uploadData = async () =>{
      const body = {
        "appAccountId": `${accountId}`,
        "integrationAccess": accessNames
      };
      const response = await fetch(process.env.REACT_APP_BASE_URL + `/api/appaccount/update-integration-access`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });

      if(response.ok){
        alert('Integration Access Updated Successfully');
        
      }
  
      if (!response.ok) {
        // navigate('/login',{replace: true})
        // handleLogout();
      }

      if(response.status === 400){
        console.log(response)
        alert('Error Updating Integration Access')
      }

      if(response.status === 403){
        // navigate('/login',{replace: true})
        // handleLogout();
      }
  
      const responsJSON = await response.json();
  }


  return (
    <div>
      <FormControl sx={{ m: 2, width: 400 }}>
        <InputLabel id="demo-multiple-chip-label">Integrations</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={accessNames}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Integrations" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem
              key={name}
              value={name}
              style={getStyles(name, accessNames, theme)}
            >
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <br></br>
      <br></br>
      <Button variant='contained' onClick={uploadData} style={{float:'right'}}>Save</Button>
    </div>
  );
}
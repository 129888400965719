import { createSlice } from "@reduxjs/toolkit";
import { verifySession } from "../actions/userActions";

var refreshToken = localStorage.getItem('refreshToken');
var accessToken = localStorage.getItem('accessToken');
var userId = localStorage.getItem('userId');
var roles;

if (refreshToken) {
  const verification = await verifySession(accessToken, userId);

  if (!verification.isValid) {
    refreshToken = '';
    accessToken = '';
    userId = '';
    roles = [];
    localStorage.clear();
  } else {
    roles = verification.roles;
  }
}

export const userSlice = createSlice({
  name: "user",
  initialState: {
    accessToken,
    refreshToken,
    userId,
    roles: roles
  },
  reducers: {
    login: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.userId = action.payload.userId;
      state.roles = action.payload.roles;
    },
    signup: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
      state.userId = action.payload.userId;
    },
    logout: state => {
      state.accessToken = "";
      state.refreshToken = "";
      state.userId = "";
      state.roles = [];
      localStorage.clear();
    },
    tokensReceived: (state, action) => {
      state.accessToken = action.payload.accessToken;
      state.refreshToken = action.payload.refreshToken;
    },
  }
});

export const selectLoggedIn = state => state.user.refreshToken ? true : false;
export const selectRoles = state => state.user.roles;

export const { 
  login, 
  signup, 
  logout,
  tokensReceived,
} = userSlice.actions;

export default userSlice.reducer;
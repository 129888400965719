import React, { useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import Home from "./Home";
import { useDispatch } from "react-redux";
import { hubspotCallbackAction } from "../../actions/userActions";

function HubspotOAuth () {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = new URLSearchParams(location.search);
  const code = params.get('code') || '';
  
  useEffect(function connectToHubspot() {
    dispatch(hubspotCallbackAction(code));
    navigate('/connections');
  }, [dispatch, navigate, code]);

  return (
    <></>
  )
}

export default HubspotOAuth;
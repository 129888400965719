import { createSlice } from "@reduxjs/toolkit";
import { getIntegrations } from "../actions/userActions";

var accessToken = localStorage.getItem('accessToken');
var hubspotConnected = false,
    hubspotInfo = {}, 
    quickbooksConnected = false, 
    quickbooksInfo = {}, 
    jiraConnected = false, 
    jiraInfo = {},
    introhiveConnected = false,
    introhiveInfo = {};

if (accessToken) {
  const integrations = await getIntegrations(accessToken);
  if (integrations) {
    hubspotConnected = integrations?.hubspotInfo?.portalId ? true : false;
    hubspotInfo = integrations?.hubspotInfo;
    quickbooksConnected = integrations?.quickbooksInfo?.companyId ? true : false;
    quickbooksInfo = integrations?.quickbooksInfo;
    jiraConnected = integrations?.jiraInfo?.username ? true : false;
    jiraInfo = integrations?.jiraInfo;
    introhiveInfo = integrations?.introhiveInfo
    introhiveConnected = integrations?.introhiveInfo?.baseUrl ? true: false
  }
} 

export const integrationsSlice = createSlice({
  name: "integrations",
  initialState: {
    hubspotConnected,
    hubspotInfo,
    quickbooksConnected,
    quickbooksInfo,
    jiraConnected,
    jiraInfo,
    introhiveConnected,
    introhiveInfo
  },
  reducers: {
    storeIntegrations: (state, action) => {
      state.hubspotConnected = action.payload.integrations.hubspotInfo.portalId ? true : false;
      state.hubspotInfo = action.payload.integrations.hubspotInfo;

      state.quickbooksConnected = action.payload.integrations.quickbooksInfo.companyId ? true : false;
      state.quickbooksInfo = action.payload.integrations.quickbooksInfo;

      state.jiraConnected = action.payload.integrations.jiraInfo.username ? true : false;
      state.jiraInfo = action.payload.integrations.jiraInfo;

      state.introhiveConnected = action.payload.integrations?.introhiveInfo?.baseUrl ? true : false;
      state.introhiveInfo = action.payload.integrations?.introhiveInfo;
    },
    clearIntegrations: (state) => {
      state.hubspotConnected = false;
      state.hubspotInfo = {};

      state.quickbooksConnected = false;
      state.quickbooksInfo = {};

      state.jiraConnected = false;
      state.jiraInfo = {};

      state.introhiveConnected = false;
      state.introhiveInfo = {}
    },
    connectedToHubspot: (state, action) => {
      state.hubspotConnected = true;
      state.hubspotInfo = action.payload.hubspotInfo;
    },
    connectedToQuickBooks: (state, action) => {
      state.quickbooksConnected = true;
      state.quickbooksInfo = action.payload.quickbooksInfo;
    },
    connectedToJira: (state, action) => {
      state.jiraConnected = true;
      state.jiraInfo = action.payload.jiraInfo;
    },
    connectedToIntrohive: (state, action) => {
      state.introhiveConnected = true;
      state.introhiveInfo = action.payload.introhiveInfo;
    },
    updateQuickBooks: (state, action) => {
      state.quickbooksInfo = { ...state.quickbooksInfo, ...action.payload }
    },
    updateJira: (state, action) => {
      state.jiraInfo = { ...jiraInfo, ...action.payload };
    },
    updateIntrohive: (state, action) => {
      state.introhiveInfo = { ...introhiveInfo, ...action.payload };
    },
    disconnectedFromHubspot: (state) => {
      state.hubspotConnected = false;
      state.hubspotInfo = {};
    },
    disconnectedFromQuickbooks: (state) => {
      state.quickbooksConnected = false;
      state.quickbooksInfo = {};
    },
    disconnectedFromJira: (state) => {
      state.jiraConnected = false;
      state.jiraInfo = {};
    },
    disconnectedFromIntrohive: (state) => {
      state.introhiveConnected = false;
      state.introhiveInfo = {};
    }
  }
});

export const selectHSConnected = state => state.integrations?.hubspotConnected ? true : false;
export const selectHSInfo = state => state.integrations?.hubspotInfo;

export const selectQBConnected = state => state.integrations?.quickbooksConnected ? true : false;
export const selectQBAccess = state => Object.keys(state.integrations?.quickbooksInfo).length > 0;
export const selectQBInfo = state => state.integrations?.quickbooksInfo;

export const selectJiraConnected = state => state.integrations?.jiraConnected ? true : false;
export const selectJiraAccess = state => Object.keys(state.integrations?.jiraInfo).length > 0;
export const selectJiraInfo = state => state.integrations?.jiraInfo;

export const selectIntrohiveConnected = state => state.integrations?.introhiveConnected ? true : false;
export const selectIntrohiveAccess = state => Object.keys(state.integrations?.introhiveInfo).length > 0;
export const selectIntrohiveInfo = state => state.integrations?.introhiveInfo;

export const {
  storeIntegrations,
  clearIntegrations,
  connectedToHubspot,
  connectedToQuickBooks,
  connectedToJira,
  connectedToIntrohive,
  updateQuickBooks,
  updateJira,
  updateIntrohive,
  disconnectedFromHubspot,
  disconnectedFromQuickbooks,
  disconnectedFromJira,
  disconnectedFromIntrohive
} = integrationsSlice.actions;

export default integrationsSlice.reducer;
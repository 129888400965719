import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Moment from 'moment';
import { io } from 'socket.io-client';
import { logout } from '../../slices/userSlice';
import { 
  clearIntegrations,
  selectIntrohiveConnected,
  selectIntrohiveInfo,
  updateIntrohive
} from '../../slices/integrationsSlice';
import styles from './HomeTabs.style';
import {
  Box,
  Button,
  Divider,
  Switch,
  Typography
} from '@mui/material';
import SyncInformation from '../../components/SyncInformation/SyncInformation';
import TimePicker from 'react-time-picker';

function IntrohiveSync() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const introhiveInfo = useSelector(selectIntrohiveInfo);
  const introhiveConnected = useSelector(selectIntrohiveConnected);

  useEffect(function redirectOnQBConnection() {
    if (!introhiveConnected) {
      navigate('/connections');
    }
  }, [introhiveConnected, navigate]);

  let syncTime = introhiveInfo?.introhiveSyncTime;
  
  if(syncTime){
    const hr = parseInt(syncTime.split(':')[0]);
    let min = parseInt(syncTime.split(':')[1]);
    syncTime = (`${new Date(new Date().setUTCHours(hr, min)).getHours()}:${new Date(new Date().setUTCHours(hr, min)).getMinutes()}`);
  } else {
    syncTime = "00:00";
  }
  
  let dailySync = introhiveInfo?.introhiveDailySync;

  const [data, setData] = useState([]);
  const [value, onTimeChange] = useState(syncTime);
  const [isChecked, toggleState]= useState(dailySync);

  const handleLogout = () => {
    dispatch(logout());
    dispatch(clearIntegrations());
  }

  function onToggle(event) {
    toggleState(()=> {
      return event.target.checked
    });
  }

  useEffect(() => {
    const socket = io(process.env.REACT_APP_BASE_URL);

    async function getLastLog(){
      try {
        console.log('Initiated getting Introhive last log');
        // Make a POST request to your login API endpoint
        const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/sync/integration-log/Introhive', {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
          }
        });
    
        if (!response.ok) {
          handleLogout();
        }

        if(response.status === 403){
          handleLogout();
        }
    
        const responsJSON = await response.json();

        setData(()=>{
            return {
              data: responsJSON.data,
              status: responsJSON.status,
              message: responsJSON.message,
              createdAt: Moment(responsJSON.updatedOn).format('YYYY-MM-DD HH:mm')
            }
        })
      } catch (error) {
        console.error('Error:', error);
        // Handle login error (e.g., show an error message to the user)
      }
    }

    function updateData(value) {
      //console.log('Received: ', value);
      if(localStorage.getItem('userId') == value.appAccId){
      const jsonData = JSON.parse(value.data);
      if (jsonData.IntegrationName === 'Introhive') {
        setData(()=>{
          return {
            status: jsonData.status,
            data: jsonData.data.logData,
            message: jsonData.message,
            createdAt: Moment(new Date(Date.now())).format('YYYY-MM-DD HH:mm')
          }
        })
      }
    }
    }

    getLastLog();

    socket.on('events', updateData);

    return () => {
      socket.off('events', updateData);
      socket.close();
    };
  }, []);

  async function saveSyncSettings() {
    try {
      let date = new Date(new Date().setHours(value.split(':')[0],value.split(':')[1]));
      
      let data = {
        "dailySync": isChecked,
        "syncTime": `${date.getUTCHours()}:${date.getUTCMinutes() < 10 ? '0'+date.getUTCMinutes() : date.getUTCMinutes()}`,
        "syncType": 'Introhive'
      }

      //  Make a POST request to your login API endpoint
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/sync/sync-settings', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (!response.ok) {
        throw new Error('Sync settings update failed');
      }

      if(response.status === 403){
        handleLogout();
      }

      if(response.ok){
        const responsJSON = await response.json();
        dispatch(updateIntrohive({ 
          introhiveDailySync: data.dailySync,
          introhiveSyncTime: data.syncTime
        }));

        alert(responsJSON.message);
      }
    } catch (error) {
      alert(error.message);
    }
  }

  async function initiateSync() {
    try {
      // Make a POST request to your login API endpoint
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/introhive/sync-introhive', {
        method: 'GET',
        headers: {
         'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        }
      });
 
      if (!response.ok) {
        throw new Error('Login failed');
      }
 
      if(response.status === 403){
        handleLogout();
      }
 
      const responsJSON = await response.json();
      
      setData(()=>{
         return {
           issuesCreated: responsJSON.totalAdded,
           issuesUpdated:  responsJSON.totalUpdated,
           status: 'Processing',
           createdAt: Moment(responsJSON.createdAt).format('YYYY-MM-DD HH:mm')
         }
      })
    } catch (error) {
      console.error('Error:', error);
      // Handle login error (e.g., show an error message to the user)
      alert(error.message);
    }
  }

  return (
    <Box sx={styles.box}>
      <Box sx={styles.wrapper}>
        <Typography variant="h5">
          Last Sync Information
        </Typography>
      </Box>
      <SyncInformation syncInformation={{data}} />

      <Divider sx={{ 
        width: '100%', 
        background: 'black',
        marginBottom: '1rem'
      }}/>

      <Typography variant="h5">
        Synchronization schedule
      </Typography>

      <>
        <Box sx={styles.textContainer}>
          <Typography sx={styles.text}>
            Daily Sync At:
          </Typography>
          <Typography>
            <TimePicker onChange={onTimeChange} value={value} disableClock={true} />
          </Typography>
        </Box>

        <Box sx={styles.textContainer}>
          <Typography sx={styles.text}>
            Sync Daily:
          </Typography>
          <>
            <Typography>Off</Typography>
            <Switch onChange={onToggle} checked={isChecked}/>
            <Typography>On</Typography>
          </>
        </Box>
      </>
      <Button 
        sx={styles.button}
        variant="outlined"
        color="primary"
        onClick={saveSyncSettings}
      >
        Save Sync Settings
      </Button>
      <Button 
        sx={styles.button}
        variant="outlined"
        color="primary"
        onClick={initiateSync}
      >
        Initiate a Sync
      </Button>
    </Box>
  )
}

export default IntrohiveSync;
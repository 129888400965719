import React, { useEffect } from 'react';
import { Link, Routes, Route } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { selectLoggedIn, selectRoles } from '../../slices/userSlice';
import { selectQBConnected, selectJiraConnected, selectIntrohiveConnected } from '../../slices/integrationsSlice';

import { 
  Container, 
  Box,
  Tabs,
  Tab
} from '@mui/material';
import Connections from '../HomeTabs/Connections';
import QuickBooksSync from '../HomeTabs/QuickBooksSync';
import JiraSync from '../HomeTabs/JiraSync';
import IntrohiveSync from '../HomeTabs/IntrohiveSync';

function Home() {
  const loggedIn = useSelector(selectLoggedIn);
  const roles = useSelector(selectRoles);

  const qbConnected = useSelector(selectQBConnected);
  const jiraConnected = useSelector(selectJiraConnected);
  const introhiveConnected = useSelector(selectIntrohiveConnected);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(function redirectOnLogout() {
    if (loggedIn) {
      if (location.pathname === '/') {
        if (roles.includes('admin')) {
          navigate('/dashboard');
        } else { 
          navigate('/connections');
        }
      }
    } else {
      navigate('/login');
    }
  }, [loggedIn, location.pathname, roles, navigate]);

  return (
    <Container sx={{ width: '75%' }}>
      <Box>
        <Tabs 
          value={location.pathname}
          variant="fullWidth"
        >
          <Tab
            label="Connections"
            value="/connections"
            component={Link}
            to="/connections"
          />
          { qbConnected && (
            <Tab
              label="QuickBooks Sync"
              value="/quickbooks-sync"
              component={Link}
              to="/quickbooks-sync"
            />
          )}
          { jiraConnected && (
            <Tab
              label="Jira Sync"
              value="/jira-sync"
              component={Link}
              to="/jira-sync"
            />
          )}
          {
            introhiveConnected && (
              <Tab
                label="Introhive Sync"
                value="/introhive-sync"
                component={Link}
                to="/introhive-sync"
              />
            )
          }
        </Tabs>
      </Box>
      <Routes>
        <Route path="/connections" Component={Connections} />
        <Route path="/quickbooks-sync" Component={QuickBooksSync} />
        <Route path="/jira-sync" Component={JiraSync} />
        <Route path='/introhive-sync' Component={IntrohiveSync}/>
      </Routes>
    </Container>
  )
}

export default Home;
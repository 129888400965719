import React, { useState, useEffect } from 'react';
import useChange from '../../customHooks/useChange';
import './QuickBooks.css';
import { useDispatch, useSelector } from 'react-redux';
import { selectRoles } from '../../slices/userSlice';
import {
  selectHSConnected, selectHSInfo,
  selectQBConnected, selectQBInfo,
  selectJiraConnected, selectJiraInfo,
  selectIntrohiveConnected, selectIntrohiveInfo,
  connectedToJira, updateJira, updateIntrohive,
  selectQBAccess, selectJiraAccess, selectIntrohiveAccess,
  connectedToIntrohive
} from '../../slices/integrationsSlice';

import styles from './HomeTabs.style';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  Divider,
  Typography
} from '@mui/material';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { useNavigate } from 'react-router-dom';
import { verifyIntrohiveCredentials, verifyJiraCredentials } from '../../actions/userActions';

const HubSpotButton = styled(Button)(() => (
  styles.hubspot
));

function Connections() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const loggedIn = useSelector(selectLoggedIn);
  const roles = useSelector(selectRoles);

  useEffect(() => {
    if (roles.includes('admin')) {
      navigate('/dashboard');
    }
  })

  const hsConnected = useSelector(selectHSConnected);
  const hsInfo = useSelector(selectHSInfo);

  const qbConnected = useSelector(selectQBConnected);
  const qbInfo = useSelector(selectQBInfo);
  const qbAccess = useSelector(selectQBAccess);
  
  const jConnected = useSelector(selectJiraConnected);
  const jiraInfo = useSelector(selectJiraInfo);
  const jiraAccess = useSelector(selectJiraAccess);

  const iConnected = useSelector(selectIntrohiveConnected);
  const introhiveInfo = useSelector(selectIntrohiveInfo);
  const introhiveAccess = useSelector(selectIntrohiveAccess);

  const [jiraEditing, setJiraEditing] = useState(false);
  const [jiraCredentials, handleJiraChange, setJiraData] = useChange({
    username: jiraInfo.username,
    token: jConnected ? '********' : ''
  });

  const [introhiveEditing, setIntrohiveEditing] = useState(false);
  const [introhiveCredentials, handleIntrohiveChange, setIntrohiveData] = useChange({
    baseUrl: introhiveInfo.baseUrl,
    apiKey: iConnected ? '********' : ''
  });

  async function submitJiraCredentials(event) {
    setJiraEditing(false);
    const verified = await verifyJiraCredentials(localStorage.getItem('accessToken'), jiraCredentials.username, jiraCredentials.token, jConnected);
    if (verified.success) {      
      // anonymize token
      setJiraData({ ...jiraCredentials, token: '********' });
      if (!jConnected) {
        dispatch(connectedToJira({ jiraInfo: { username: jiraCredentials.username }}));
      } else {
        dispatch(updateJira({ username: jiraCredentials.username }));
      }
    } else {
      setJiraEditing(true);
    }
    alert(verified.message);
  }

  function modifyJiraCredentials() {
    // require user to re-enter token if provided before
    setJiraData({ ...jiraCredentials, token: '' });
    setJiraEditing(true);
  }

  function cancelModifyJiraCredentials() {
    if (jConnected) setJiraData({ username: jiraInfo.username, token: '********' });
    else {
      setJiraData({ username: '', token: '' });
    }
    setJiraEditing(false);
  }

  async function submitIntrohiveCredentials(event) {
    setIntrohiveEditing(false);
    const verified = await verifyIntrohiveCredentials(localStorage.getItem('accessToken'), introhiveCredentials.baseUrl, introhiveCredentials.apiKey, iConnected);
    if (verified.success) {
      setIntrohiveData({ ...introhiveCredentials, apiKey: '********' });
      if (!iConnected) {
        dispatch(connectedToIntrohive({ introhiveInfo: { baseUrl: introhiveCredentials.baseUrl }}));
      } else {
        dispatch(updateIntrohive({ baseUrl: introhiveCredentials.baseUrl }));
      }
    } else {
      setIntrohiveEditing(true);
    }
    alert(verified.message);
  }

  function modifyIntrohiveCredentials() {
    // require user to re-enter token if provided before
    setIntrohiveData({ ...introhiveCredentials, apiKey: '' });
    setIntrohiveEditing(true);
  }

  function cancelModifyIntrohiveCredentials() {
    if (iConnected) setIntrohiveData({ baseUrl: introhiveInfo.baseUrl, apiKey: '********' });
    else {
      setIntrohiveData({ baseUrl: '', apiKey: '' });
    }
    setIntrohiveEditing(false);
  }

  return (
    <>
      { roles.includes('client') && (
        <div style={styles.root}>
          { hsConnected && (
            <>
              { qbAccess && (
                <>
                  <Box sx={styles.box}>
                    <Typography sx={styles.typography} variant="h4">
                      QuickBooks Settings
                    </Typography>
                    
                    { qbConnected && (
                      <>
                        <Typography sx={styles.typography} variant="body1">
                        Connected to: {`${qbInfo.companyName} (${qbInfo.companyId})`}
                        </Typography>
                      </>
                    )}
                    { !qbConnected && (
                      <a href={`${process.env.REACT_APP_BASE_URL}/api/quickbook/install`} className="quickbooks"> </a>
                    )}
                  </Box>

                  <Divider sx={{ width: '100%', background: 'black'}}/>
                </>
              )}
              
              { jiraAccess && (
                <>
                  <Box sx={styles.box}>
                    <Typography sx={styles.typography} variant="h4">
                      Jira Settings
                    </Typography>

                    <ValidatorForm 
                      style={styles.form}
                      onSubmit={submitJiraCredentials} 
                      instantValidate={false}
                    >
                      <TextValidator 
                        sx={styles.form_field}
                        disabled={!jiraEditing}
                        label="Username"
                        name="username"
                        value={jiraCredentials.username ?? ''}
                        onChange={handleJiraChange}
                        validators={['required']}
                        errorMessages={['this field is required']}
                      />
                      <TextValidator 
                        sx={styles.form_field}
                        disabled={!jiraEditing}
                        label="API Token"
                        name="token"
                        value={jiraCredentials.token ?? ''}
                        onChange={handleJiraChange}
                        type="password"
                        validators={['required']}
                        errorMessages={['this field is required']}
                      />

                      { jiraEditing && (
                        <>
                          <Button
                            sx={styles.button}
                            variant="outlined"
                            color="primary"
                            onClick={cancelModifyJiraCredentials}
                          >
                            Cancel
                          </Button>
                          <Button
                            sx={styles.button}
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Submit Credentials
                          </Button>
                        </>
                      )}
                    </ValidatorForm>

                    { !jConnected && !jiraEditing && (
                      <Button
                        sx={styles.jira_settings_button}
                        variant="outlined"
                        color="primary"
                        onClick={modifyJiraCredentials}
                      >Add Jira Credentials</Button>
                    )}
                    { jConnected && !jiraEditing && (
                      <Button
                        sx={styles.jira_settings_button}
                        variant="outlined"
                        color="primary"
                        onClick={modifyJiraCredentials}
                      >Edit Jira Credentials</Button>
                    )}
                    
                  </Box>

                  <Divider sx={{ width: '100%', background: 'black'}}/>
                </>
              )}

              { introhiveAccess && (
                <>
                  <Box sx={styles.box}>
                    <Typography sx={styles.typography} variant="h4">
                      Introhive Settings
                    </Typography>

                    <ValidatorForm 
                      style={styles.form}
                      onSubmit={submitIntrohiveCredentials} 
                      instantValidate={false}
                    >
                      <TextValidator 
                        sx={styles.form_field}
                        disabled={!introhiveEditing}
                        label="Environment"
                        name="baseUrl"
                        value={introhiveCredentials.baseUrl ?? ''}
                        onChange={handleIntrohiveChange}
                        validators={['required']}
                        errorMessages={['this field is required']}
                      />
                      <TextValidator 
                        sx={styles.form_field}
                        disabled={!introhiveEditing}
                        label="API Key"
                        name="apiKey"
                        value={introhiveCredentials.apiKey ?? ''}
                        onChange={handleIntrohiveChange}
                        type="password"
                        validators={['required']}
                        errorMessages={['this field is required']}
                      />

                      { introhiveEditing && (
                        <>
                          <Button
                            sx={styles.button}
                            variant="outlined"
                            color="primary"
                            onClick={cancelModifyIntrohiveCredentials}
                          >
                            Cancel
                          </Button>
                          <Button
                            sx={styles.button}
                            variant="contained"
                            color="primary"
                            type="submit"
                          >
                            Submit Credentials
                          </Button>
                        </>
                      )}
                    </ValidatorForm>

                    { !iConnected && !introhiveEditing && (
                      <Button
                        sx={styles.jira_settings_button}
                        variant="outlined"
                        color="primary"
                        onClick={modifyIntrohiveCredentials}
                      >Add Introhive Credentials</Button>
                    )}
                    { iConnected && !introhiveEditing && (
                      <Button
                        sx={styles.jira_settings_button}
                        variant="outlined"
                        color="primary"
                        onClick={modifyIntrohiveCredentials}
                      >Edit Introhive Credentials</Button>
                    )}
                    
                  </Box>

                  <Divider sx={{ width: '100%', background: 'black'}}/>
                </>
              )}
            </>
          )}
          
          <Box sx={styles.box}>
            <Typography sx={styles.typography} variant="h4">
              HubSpot Settings
            </Typography>

            { hsConnected && (
              <>
                <Typography sx={styles.typography} variant="body1">
                Connected to: {`${hsInfo.domain} (${hsInfo.portalId})`}
                </Typography>
              </>
            )}
            { !hsConnected && (
              <HubSpotButton href={`${process.env.REACT_APP_BASE_URL}/api/hubspot/install`}>
                <span style={{ padding: '0 1rem' }}>Connect to HubSpot</span>
              </HubSpotButton>
            )}
          </Box>
        </div>
      )}
    </>
  )
}

export default Connections;
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);

export function storeInLocalStorage ({ 
  accessToken, 
  expiresIn,
  refreshToken, 
  userId,
  // quickbooksDailySync,
  // quickbooksSyncTime,
  // jiraDailySync,
  // jiraSyncTime
  // integrations
}) {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem(
    'accessTokenExpiresAt',
    dayjs().add(expiresIn, 's')
  );
  localStorage.setItem('refreshToken', refreshToken);
  // localStorage.setItem('quickbooksDailySync', quickbooksDailySync)
  // localStorage.setItem('quickbooksSyncTime', quickbooksSyncTime);
  // localStorage.setItem('jiraDailySync', jiraDailySync);
  // localStorage.setItem('jiraSyncTime', jiraSyncTime);
  // localStorage.setItem('integrations', JSON.stringify(integrations));
  if (userId) localStorage.setItem('userId', userId);
}

export async function getAccessToken (dispatch) {
  const accessTokenExpiresAt = localStorage.getItem('accessTokenExpiresAt');
  const accessTokenExpired = dayjs().isSameOrAfter(dayjs(accessTokenExpiresAt));
  const oldAccessToken = localStorage.getItem('accessToken');
  if (!accessTokenExpired) return oldAccessToken;
  else {
    const refreshToken = localStorage.getItem('refreshToken');
    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/api/appaccount/refresh-tokens', { 
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ refreshToken }) 
      });

      const result = await response.json();
      if (response.status === 200) {
        const { 
          accessToken: newAccessToken,
          expiresIn: newExpiresIn,
          refreshToken: newRefreshToken
        } = result;
        storeInLocalStorage({
          accessToken: newAccessToken,
          expiresIn: newExpiresIn,
          refreshToken: newRefreshToken
        });
        dispatch()
        return newAccessToken;
      }
    } catch (error) {
      return oldAccessToken;
    }
  }
}
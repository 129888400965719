import React, { useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { Box, Button, Grid } from '@mui/material';
import Chip from '@mui/material/Chip';
import DoneIcon from '@mui/icons-material/Done';
import dayjs from 'dayjs';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import ModalDialog from './modal';
import logout from '../../slices/userSlice'
import { clearIntegrations } from '../../slices/integrationsSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#4287f5',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function preventDefault(event) {
  event.preventDefault();
}

export default function Accounts() {
    const [resp, setData] = useState({});
    const [pageInfo, setPageInfo] = useState({currentPage: 1, pageSize: 5})
    const [isOpen, showModal] = useState(false)
    const [modalTitle, setTitle] = useState('');
    const [mode, setMode] = useState('');
    const [accountId, setAccount] = useState('')
    const [status, setStatus] = useState(false)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [size, setSize] = React.useState(5);

    const handleChange = (event) => {
      setSize(event.target.value);
      getAppAccounts(1, event.target.value);
    };

    const handleStatus =(event)=>{
      setStatus(event.target.checked)
    }

    const handleLogout = () => {
      dispatch(logout());
      dispatch(clearIntegrations());
    }
    
  function BasicPagination({count}) {
    return (
      <Stack spacing={2} margin={5} alignItems={'center'}>
        <Pagination count={count} page={pageInfo.currentPage} color="primary" onChange={(e, value)=>{
          setPageInfo(()=>{
              let data = {
                currentPage: value,
                pageSize: 5
              }

              getAppAccounts(data.currentPage, data.pageSize)
              return data
            })
        }} />
      </Stack>
    );
  }

    async function getAppAccounts(currentPage, pageSize){
      try {
        // Make a POST request to your login API endpoint
        const response = await fetch(process.env.REACT_APP_BASE_URL + `/api/appaccount/app-accounts?currentPage=${currentPage}&pageSize=${pageSize}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json'
          }
        });
    
        if (!response.ok) {
          navigate('/login',{replace: true})
          handleLogout();
        }

        if(response.status === 403){
          navigate('/login',{replace: true})
          handleLogout();
        }
    
        const responsJSON = await response.json();
        setData(()=>{
            return responsJSON;
        })
       
      } catch (error) {
        console.error('Error:', error);
        // Handle login error (e.g., show an error message to the user)
      }
    }

    useEffect(() => {    
        getAppAccounts(pageInfo.currentPage, pageInfo.pageSize);
      }, []);

  return (
    <React.Fragment>
      <Title>App Accounts</Title>
      <Button variant='outlined' onClick={()=>{
                showModal(true)
                setTitle('Create Account')
                setMode('account')
              }}>Create Account</Button>
      <Table size="medium" style={{marginTop: 30}}>
        <TableHead>
          <TableRow>
            <StyledTableCell style={{fontWeight: 'bold'}}>Last Access Time</StyledTableCell>
            <StyledTableCell style={{fontWeight: 'bold'}}>Email</StyledTableCell>
            <StyledTableCell style={{fontWeight: 'bold'}}>Roles</StyledTableCell>
            <StyledTableCell style={{fontWeight: 'bold'}}>Integration Access</StyledTableCell>
            <StyledTableCell style={{fontWeight: 'bold'}}>Account Status</StyledTableCell>
            <StyledTableCell style={{fontWeight: 'bold'}}> Update Integration</StyledTableCell>
            <StyledTableCell style={{fontWeight: 'bold'}}> Change Password</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {resp?.data?.map((row) => (
            <StyledTableRow key={row._id}>
              <TableCell>{dayjs(row.updatedAt).format('dddd, MMMM D, YYYY h:mm A')}</TableCell>
              <TableCell>{row.email}</TableCell>
              <TableCell>{row?.roles?.map(x=> <Chip
                label={x}
                variant='outlined'
                color='primary'
                deleteIcon={<DoneIcon />}
                />)}</TableCell>
              <TableCell>{row?.integrationAccess?.map(x=> <Chip
                label={x}
                variant='outlined'
                color='success'
                deleteIcon={<DoneIcon />}
                />)}</TableCell>
                <TableCell>
                <Switch checked={row?.status == 'Active' ? true: false} onChange={handleStatus} onClick={()=>{
                  showModal(true)
                  setTitle('Account Status')
                  setMode('status')
                  setAccount(row?._id)

                }} />
                </TableCell>
              <TableCell align="center"><Button variant='outlined' onClick={()=>{
                showModal(true)
                setTitle('Integration Access Modal')
                setMode('integrations')
                setAccount(row?._id)
              }}>Update</Button></TableCell>
              <TableCell align="center"><Button variant="outlined" onClick={()=>{
                showModal(true)
                setTitle('Change Password')
                setMode('password')
                setAccount(row?._id)
              }}>Update</Button></TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      <Grid container spacing={2}>
        <Grid item xs={6} alignContent={'end'} alignItems={'end'}>
          <BasicPagination count={resp?.totalPageCount}/>
        </Grid>
        <Grid item xs={6}>
          <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={size}
              onChange={handleChange}
              style={{marginTop:25, outline:'0px', border:'0px'}}
            >
              <MenuItem value={5}>5 per page</MenuItem>
              <MenuItem value={10}>10 per page</MenuItem>
              <MenuItem value={25}>25 per page</MenuItem>
              <MenuItem value={50}>50 per page</MenuItem>
            </Select>
          </Grid>
        </Grid>
      <ModalDialog isOpen={isOpen} title={modalTitle} mode={mode} setClose={showModal} accountId={accountId} getAccounts={getAppAccounts} status={status}/>
    </React.Fragment>
  );
}